export const environment = {
  name:'development',
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyAiv6udxz3DA7IJS7wKZf7sgXDHyYoqJG4",
      authDomain: "fir-test-590da.firebaseapp.com",
      projectId: "fir-test-590da",
      storageBucket: "fir-test-590da.appspot.com",
      messagingSenderId: "296555930609",
      appId: "1:296555930609:web:4601f2c5daa9df4c701a50",
      measurementId: "G-CP32ETRMYC"
    },
    encryptionKey: 'Ferragamo@2024!',
    gaProperty:'G-C5NJ1DWCLX'
  };
  