import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'game',
    redirectTo: 'game/home',
    pathMatch: 'full'
  },
  {
    path: 'game/home',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city',
    loadChildren: () => import('./pages/path/path.module').then( m => m.PathPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city/result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city/quiz',
    loadChildren: () => import('./pages/quiz/quiz.module').then( m => m.QuizPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city/success',
    loadChildren: () => import('./pages/success/success.module').then( m => m.SuccessPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city/failure',
    loadChildren: () => import('./pages/failure/failure.module').then( m => m.FailurePageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'game/path/:city/path-end',
    loadChildren: () => import('./pages/path-end/path-end.module').then( m => m.PathEndPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/chapter-selection/chapter-selection.module').then( m => m.ChapterSelectionPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'chapter/:city',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule),
    canActivate: [AuthGuard],
    data: {enableAuthGuard: {production:false, staging:true, development:true}}
  },
  {
    path: 'denied',
    loadChildren: () => import('./pages/denied/denied.module').then( m => m.DeniedPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/singin.module').then( m => m.SignInPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
